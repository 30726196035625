$(function(){
    
    $(".slider").slick({
        slidesToShow: 1, //表示させたい枚数を入れる。1枚ずつなら1
        autoplay: true, //自動でスライドさせたい場合はtrue
    });
    
    $(".btn-guestopen").click(function(){
      $(".guest-box").toggleClass("show");
    });
    
    $(window).on('load scroll',function(){
    var sct = $(window).scrollTop();
    $('.fadeIn').each(function(){
      var elemPos = $(this).offset().top;
      var windowHeight = $(window).height();
      if (sct > elemPos - windowHeight + 100){
        $(this).addClass('scrollIn');
      }else{
        $(this).removeClass('scrollIn');
      }
    });
      
      }).on('resize',function(){

  });
    
});